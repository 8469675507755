<template>
    <div>
        <div v-if="new_password !== ''" class="verification-container">
            <div class="verification-text">{{$t('verification.your-new-password')}}</div>
            <div class="verification-new-password">{{new_password}}</div>
            <div class="verification-text">{{$t('verification.save-it-somewhere')}}</div>
        </div>
    </div>
</template>

<script>
    import sha256 from "sha256"


    export default {
        data() {
            return {
                new_password: "",
            }
        },
        async beforeCreate() {
            if (this.$route.params.code) {
                let code = this.$route.params.code;

                let password           = this.func.generate_password(50);
                let encrypted_password = sha256.x2(password);

                let response = await this.func.ax("/php/verify_password.php", {
                    code    : code,
                    password: encrypted_password,
                });

                if (response === "success") {
                    await this.$user.init();
                    this.new_password = password;
                    this.eventbus.$emit("SB", "verification.success-new-password", "success");
                }
                else
                    await this.$router.push(this.$variables.v.navigation.default);
            }
        },
        directives: {
            "sha256": sha256
        },
    }
</script>